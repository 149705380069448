<script setup>
import { NTabs, NTabPane, NButton } from 'naive-ui'
import axios from 'axios'
import { ElMessage } from 'element-plus'
</script>

<template>
    <div class="center">
        <el-scrollbar>
            <div :style="{ width: '880px', display: 'flex', marginBottom: '100px' }">
                <div class="divbg"
                    :style="{ width: '880px', height: '300px', position: 'absolute', backgroundColor: currentProject.color, marginTop: '49px' }">
                </div>
                <div class="div1"></div>

                <el-image class="no-select" style="width: 300px;height: 300px;flex:none;margin-top: 49px;"
                    src="image/QQ图片20240713125331.png"></el-image>
                <div class="div1"></div>
                <n-tabs v-model:value="tabSelect" type="card" @update:value="tabChange">
                    <template #prefix>
                        <div style="width:0px"></div>
                    </template>
                    <n-tab-pane v-for="p in pvProjects" :key="p.pvProjectId" :name="p.pvProjectId"
                        :style="{ backgroundColor: 'transparent', height: '284px', padding: '8px' }">
                        <template #tab>
                            <div :class="tabSelect === p.pvProjectId ? 'mytab-active' : 'mytab'"
                                :style="{ backgroundColor: p.color }">
                                <span
                                    :class="tabSelect === p.pvProjectId ? 'mytab-text-active no-select' : 'mytab-text no-select'">{{
                                        p.songLength
                                    }}</span>
                            </div>
                        </template>
                        <div v-if="p.status !== 2" style="display:flex;flex-direction:column">
                            <span v-for="c in p.contents" :key="c">{{ c }}</span>
                        </div>
                        <div v-if="p.status === 2" style="display:flex;flex-direction:column">
                            <span>done</span>
                        </div>
                    </n-tab-pane>
                </n-tabs>
                <div class="div2" style="display: flex;">
                    <n-button round color="#ffffff"
                        :style="{ alignSelf: 'flex-end', marginBottom: '16px', color: currentProject.color, fontSize: '22px' }"
                        @click="urge()">辣手催花</n-button>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
export default {
    name: "DDL",
    data() {
        return {
            apiUrl: 'https://api.hotarukawa.live',
            pvProjects: [],
            tabSelect: 0,
            currentProject: {}
        }
    },
    methods: {
        ddlLoadData() {
            let _this = this
            axios({
                method: 'get', url: this.apiUrl + '/api/ddl'
            }).then(function (response) {
                _this.pvProjects = response.data.items
                _this.tabSelect = response.data.items[0].pvProjectId
                _this.currentProject = response.data.items[0]
            })
        },
        tabChange(id) {
            this.currentProject = this.pvProjects.filter(x => x.pvProjectId === id)[0]
        },
        urge() {
            //let _this = this
            axios({
                method: 'post', url: this.apiUrl + '/api/ddl/urge', data: { pVProjectId: this.currentProject.pvProjectId }
            }).then(function () {
                ElMessage({
                    message: '成功揪下来了一片向日葵花瓣，总觉得主播又变秃了一点……',
                    type: 'success',
                })
            }).catch(function (error) {
                if (error.status === 409)
                    ElMessage.error('大失败! 发量稀疏的向日葵给了你一拳，然后带着服务器去钓鱼了!')
            })
        }
    },
    mounted() {
        this.ddlLoadData()
    }
}
</script>

<style>
.center {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Zhifeiji;
    font-size: 22px;
}

.el-scrollbar__wrap{
    display: flex;
    align-items: center;
}

.mytab {
    height: 100%;
    font-size: 22px;
    width: 4px;
    padding: 8px;
    color: white;
    border-radius: 4px 4px 0 0;
    transition: width 1s cubic-bezier(0.16, 1, 0.3, 1);
}

.mytab:hover {
    width: fit-content;
    transition: width 1s cubic-bezier(0.16, 1, 0.3, 1);
}

.mytab-active {
    height: 100%;
    font-size: 22px;
    padding: 8px 16px;
    color: white;
    border-radius: 4px 4px 0 0;
    transition: width 1s cubic-bezier(0.16, 1, 0.3, 1);
    width: fit-content;
}


.n-tabs .n-tabs-tab-wrapper {
    transition: width 1s cubic-bezier(0.16, 1, 0.3, 1);

}

.n-tabs .n-tabs-pane-wrapper {
    overflow: visible;
}

.n-tabs .n-tabs-tab-wrapper:hover {
    width: fit-content;
    transition: width 1s cubic-bezier(0.16, 1, 0.3, 1);
}


.mytab-text {
    opacity: 0;
    transition: opacity 1s cubic-bezier(0.16, 1, 0.3, 1);
}

.mytab-text-active {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.16, 1, 0.3, 1);
}

.mytab:hover .mytab-text {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.16, 1, 0.3, 1);
}

.n-tabs .n-tabs-tab {
    padding: 0;
}

.n-tabs .n-tab-pane {
    padding: 8px !important;
    color: white;
}

.n-tabs .n-tabs-nav.n-tabs-nav--top.n-tabs-nav--card-type .n-tabs-tab {
    border: 0 !important;
}

.n-tabs .n-tabs-nav.n-tabs-nav--top.n-tabs-nav--card-type .n-tabs-tab-pad {
    border: 0 !important;
}

.n-tabs .n-tabs-nav.n-tabs-nav--top.n-tabs-nav--card-type .n-tabs-pad {
    border: 0 !important;
}

.n-tabs .n-tabs-nav.n-tabs-nav--card-type .n-tabs-nav__prefix,
.n-tabs .n-tabs-nav.n-tabs-nav--card-type .n-tabs-nav__suffix {
    border: 0 !important;
}

.n-tabs .n-tabs-pane-wrapper {
    border-radius: 4px;
}

:root {
    interpolate-size: allow-keywords;
}

.n-tabs.n-tabs--top .n-tab-pane {
    animation: background-color 1s cubic-bezier(0.16, 1, 0.3, 1);
    opacity: 0;
    animation: fadeIn 1s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        /* 动画开始时透明度为 0 */
    }

    to {
        opacity: 1;
        /* 动画结束时透明度为 1 */
    }
}

.divbg {
    transition: background-color 1s cubic-bezier(0.16, 1, 0.3, 1);
}

.div1 {
    width: 32px;
    height: 300px;
    margin-top: 49px;
    flex: none;
}

.div2 {
    margin-left: 16px;
    width: 140px;
    height: 300px;
    margin-top: 49px;
    flex: none;
}

.no-select {
    user-select: none;
}
</style>
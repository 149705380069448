<script setup>
import { computed } from 'vue'
</script>

<template>
  <div v-if="isHideTemplate" style="width:100%;position: fixed;z-index: 1000;">
    <el-row class="center-row" align="middle"
      style="height: 100px;background-color: var(--el-color-primary-light-7);box-shadow: 0 0 10px #888888;">
      <el-col style="height:80px" :xs="22" :sm="18" :md="18" :lg="16" :xl="16">
        <el-image id="logo" style="width: 148.391px;height:60px;padding: 10px 0;" src="icon/logo.png"
          @click="$router.push('/')"></el-image>
        <el-menu style="height:80px;float:right;" :ellipsis="false" :default-active="$route.fullPath"
          mode="horizontal" menu-trigger="click" @open="isSubMenuOpen = true" @close="isSubMenuOpen = false"
          @select="isSubMenuOpen = false">
          <el-menu-item class="hidden-md-and-down" index="/#intro" @click="$router.push('/#intro')">简介</el-menu-item>
          <el-menu-item class="hidden-md-and-down" index="/#video" @click="$router.push('/#video')">视频</el-menu-item>
          <el-menu-item class="hidden-md-and-down" index="/#image" @click="$router.push('/#image')">图片</el-menu-item>
          <el-menu-item class="hidden-md-and-down" index="/#qa" @click="$router.push('/#qa')">Q&A</el-menu-item>
          <el-menu-item class="hidden-md-and-down" index="/songList'" @click="$router.push('/songList')">歌单</el-menu-item>
          <el-menu-item class="hidden-md-and-down" index="/askbox"
            @click="open('https://candy.vupgo.com/330525028', '_blank')">提问箱</el-menu-item>
          <el-menu-item class="hidden-md-and-down" index="/fanart" @click="$router.push('/fanart')">二创守则</el-menu-item>
          <el-sub-menu index="7" class="hidden-lg-and-up">
            <template #title>
              <el-image id="menu-icon" :class="isSubMenuOpen ? 'el-sub-menu_open' : 'el-sub-menu_close'"
                style="width: 60px;height:60px;" src="icon/menu.webp"></el-image>
            </template>
            <el-menu-item index="/#intro" @click="$router.push('/#intro')">简介</el-menu-item>
            <el-menu-item index="/#video" @click="$router.push('/#video')">视频</el-menu-item>
            <el-menu-item index="/#image" @click="$router.push('/#image')">图片</el-menu-item>
            <el-menu-item index="/#qa" @click="$router.push('/#qa')">Q&A</el-menu-item>
            <el-menu-item index="/songList" @click="$router.push('/songList')">歌单</el-menu-item>
            <el-menu-item index="/askbox" @click="open('https://candy.vupgo.com/330525028')">提问箱</el-menu-item>
            <el-menu-item index="/fanart" @click="$router.push('/fanart')">二创守则</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </el-col>
    </el-row>
  </div>
  <el-container>
    <el-main style="margin-top: 100px;">
      <router-view />
    </el-main>
    <div v-if="isHideTemplate"
      style="border:solid 1px var(--el-color-primary);border-radius: 100%; width: 50px; height:50px;position: -webkit-sticky;position: sticky; bottom:20px; left: calc(100% - 70px);;display: flex;align-items: center; justify-content: end;">
      <el-image ref="cover" :class="isAudioPlaying ? 'rotation' : 'rotation rotation_pause'"
        style="width:50px; height:50px;border-radius: 100%;position: absolute;opacity: 0.7;" :src="currentBgm.cover"
        fit="cover"></el-image>
      <div class="kawa-pointer-cursor"
        style="height: 50px;width: 50px;background: rgba(255,255,255,0.0);border-radius: 100%;position: absolute;z-index: 1;"
        @click.exact="switchAudioPlaying" @click.ctrl.exact="nextBgm">
      </div>
      <div
        style="position: absolute;width:50px;text-align:center;font-size:30px;margin-bottom: 2px;color:var(--el-color-primary)">
        ♪</div>
      <div v-if="!isAudioPlaying"
        style="position: absolute;width:50px;text-align:center;font-size:30px;margin-bottom: 2px;font-family: '微软雅黑';color:var(--el-color-primary)">
        ╲</div>
    </div>
    <el-footer v-if="isHideTemplate" style="background-color:var(--el-color-primary-light-5);height: 250px;margin-top: 15px;">
      <el-row class="footer-row footer-background footer-background-x" style="margin:0px 0 0px 0;">
        <el-col :xs="11" :sm="9" :md="9" :lg="8" :xl="8">
          <div class="footer-h1">传送门</div>
          <el-link class="footer-link" :underline="false" href="https://space.bilibili.com/330525028"
            target="_blank">个人空间</el-link>
          <el-link class="footer-link" :underline="false" href="https://live.bilibili.com/21874911"
            target="_blank">直播间</el-link>
          <el-link class="footer-link" :underline="false" href="https://space.bilibili.com/490809352"
            target="_blank">录播</el-link>
          <el-link class="footer-link" :underline="false" href="https://mzh.moegirl.org.cn/%E8%90%A4%E5%B7%9D"
            target="_blank">萌娘百科</el-link>
          <el-link class="footer-link" :underline="false" href="https://afdian.net/a/hotarukawa"
            target="_blank">爱发电</el-link>
          <el-link class="footer-link" :underline="false" href="https://jq.qq.com/?_wv=1027&k=S9nSVnDe"
            target="_blank">QQ群</el-link>
          <div style="font-size:12px;position:absolute;bottom:30px;color:#333333"><span>© 2023-2025 蛍川</span></div>
        </el-col>
        <el-col :xs="11" :sm="9" :md="9" :lg="8" :xl="8" style="height:250px">
        </el-col>
      </el-row>
    </el-footer>
  </el-container>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      isSubMenuOpen: false,
      bgmList: [{ index: 0, cover: 'cover/011eab09d75b4e668dcde2a8a6a51eaa258a012f.jpg@320w_200h_1c_!web-space-upload-video.webp', audio: 'audio/freude.mp3' },
      { index: 1, cover: 'cover/b1ccb7cfead778121a90e85c5fb6bf78ddb82232.jpg@320w_200h_1c_!web-space-upload-video.webp', audio: 'audio/水仙与酸雨.mp3' },
      { index: 2, cover: 'cover/1e41cac8159b085d1992ede1c68f315e08ad76f6.jpg@320w_200h_1c_!web-space-upload-video.webp', audio: 'audio/行星环.mp3' },
      { index: 3, cover: 'cover/eb1f365a2733e60b489a4863ca951a14acf9983e.jpg@320w_200h_1c_!web-space-upload-video.webp', audio: 'audio/ルーマー.mp3' },
      { index: 4, cover: 'cover/cfb07794b7be6cacb18bdfa31757184307c4e793.jpg@320w_200h_1c_!web-space-upload-video.webp', audio: 'audio/chocolate milk.mp3' },],
      currentBgm: null,
      audioObject: null,
      isAudioFirstPlay: true,
      isAudioPlaying: false,
      isHideTemplate: computed(() => {
        return this.$router.currentRoute.value.name !== 'ddl'
      })
    }
  },
  created() {
    this.currentBgm = this.getRandomBgm()
    this.audioObject = new Audio()
    this.audioObject.src = this.currentBgm.audio
    var nb = this.nextBgm
    this.audioObject.addEventListener('ended', function () {
      nb()
    }, false);
    this.audioObject.volume = 0.1
  },
  methods: {
    open(url) {
      window.open(url)
    },
    getRandomBgm() {
      if (this.currentBgm === null) {
        let randomIndex = Math.floor(Math.random() * this.bgmList.length + 0)
        return this.bgmList[randomIndex]
      }
      else {
        let cb = this.currentBgm
        var filtered = this.bgmList.filter(function (value) {
          return value.index !== cb.index;
        });
        let randomIndex = Math.floor(Math.random() * filtered.length + 0)
        return filtered[randomIndex]
      }
    },
    switchAudioPlaying() {
      this.isAudioPlaying = !this.isAudioPlaying
      if (this.isAudioPlaying)
        this.audioObject.play()
      else
        this.audioObject.pause()
    },
    nextBgm() {
      this.$refs.cover.$forceUpdate()
      this.currentBgm = this.getRandomBgm()
      this.audioObject.src = this.currentBgm.audio
      if (this.isAudioPlaying)
        this.audioObject.play()
    }
  }
}
</script>

<style>
@font-face {
  font-family: "Maoken";
  src: url("../public/font/MaokenAssortedSans-Lite.otf") format("truetype");
}

@font-face {
  font-family: "CangerYuyang";
  src: url("../public/font/站酷仓耳渔阳体-W03.ttf") format("truetype");
}

@font-face {
  font-family: "Jiexing";
  src: url("../public/font/江城解星体V1.3.ttf") format("truetype");
}

@font-face {
  font-family: "Zhifeiji";
  src: url("../public/font/HYZhiFeiJiW.ttf") format("truetype");
}

:root {
  --el-color-primary: #e6a23c;
  --el-color-primary-light-3: #eebe77;
  --el-color-primary-light-5: #f3d19e;
  --el-color-primary-light-7: #f8e3c5;
  --el-color-primary-light-8: #faecd8;
  --el-color-primary-light-9: #fdf6ec;
  --el-color-primary-dark-2: #b88230;
  --el-fill-color-blank: transparent;
}

body {
  margin: 0;
  background-color: var(--el-color-primary-light-5);
}

.n-scrollbar-rail__scrollbar{
  z-index: 1001 !important;
}

#app{
  display: flex;
  flex-direction: column;
}

.el-menu {
  border-bottom: none;
  font-family: 'Maoken';
}

.el-menu-item {
  font-size: 18px;
}

.el-menu--horizontal {
  border-bottom: 0
}

.el-menu--horizontal>.el-menu-item {
  border-bottom: 0px solid transparent
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 0px solid transparent
}

.el-menu--horizontal>.el-sub-menu.is-active .el-sub-menu__title {
  border-bottom: 0px solid transparent
}

.el-menu--horizontal>.el-sub-menu .el-sub-menu__icon-arrow {
  display: none;
}

.el-sub-menu__title {
  padding: 10px;
  border-radius: 40px;
}

.el-menu--horizontal>.el-sub-menu .el-sub-menu__title:hover {
  background: var(--el-color-primary-light-9);
}

.el-sub-menu_open {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transition: transform .5s;
}

.el-sub-menu_close {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transition: transform .5s;
}

.el-popper.is-light {
  background: var(--el-color-primary-light-7);
  border: 1px solid var(--el-color-primary-light-5);
}

.el-main {
  --el-main-padding: 0 0 0px 0
}

.el-container {
  background-image: url("../public/image/background.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-color: var(--el-color-primary-light-9);
}

@media only screen and (max-width:1199px) {
  .el-container {
    background-size: 80%;
  }
}

.center-row {
  align-items: center;
  justify-content: center;
}

.footer-background {
  background-image: url('../public/image/footer-background.jpg');
  background-size: 1000px;
  background-repeat: no-repeat;
  background-position-y: -130px !important;
  mix-blend-mode: multiply;
}

@media only screen and (min-width: 0px) {
  .footer-background-x {
    background-position: center;
  }
}

@media only screen and (min-width:1200px) {
  .footer-background-x {
    background-position-x: calc(79.1666% + -100px);
  }
}

.rotation {
  animation: rotation 15s linear infinite;
}

.rotation_pause {
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.footer-row {
  align-items: start;
  justify-content: center;
}

.footer-h1 {
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  font-family: 'Maoken';
  color: #333333;
  margin-top: 10px;
}

.footer-link {
  margin: 0 20px 20px 0;
  font-size: 16px;
}
</style>
<template>
    <el-row class="center-row" style="margin:10px 0 20px 0">
        <el-col :xs="22" :sm="18" :md="18" :lg="16" :xl="16">
            <div style="display:flex;flex-wrap:wrap">
                <el-check-tag :checked="filter.size === 0" @change="checkAllChange">全部</el-check-tag>
                <el-check-tag style="" v-for="(tag, index) in allTags" :key="index" :checked="filter.has(tag)"
                    @change="(value) => tagChange(tag, value)">{{ tag }}</el-check-tag>
            </div>
            <div style="display:flex;align-items: center;">
                <el-input v-model="keyword" placeholder="搜索歌曲、歌手" size="large" clearable @input="getPageData" />
                <el-button title="从当前筛选结果里随机复制一首" class="button-roll" @click="roll">ROLL一首</el-button>
            </div>
            <el-table :data="this.pageList" @row-dblclick="copySongName">
                <el-table-column prop="Name" label="歌曲">
                    <template #default="scope">
                        <span>{{ scope.row.Name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Artists" label="歌手">
                    <template #default="scope">
                        <span>{{ scope.row.Artists.join(',') }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Tags" label="分类">
                    <template #default="scope">
                        <span>{{ scope.row.Tags.join(',') }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :page-size="15" :pager-count="5" :total="totalCount"
                @current-change="pageChange" />
        </el-col>
    </el-row>
</template>

<script>
import data from "@/assets/songList.json"
import { ElNotification } from 'element-plus'
import useClipboard from 'vue-clipboard3'

const { toClipboard } = useClipboard()

export default {
    name: "SongList",
    data() {
        return {
            filterData: [],
            pageList: [],
            pageSize: 15,
            startIndex: 0,
            totalCount: 0,
            keyword: '',
            allTags: [],
            filter: [],
            isCheckAll: true
        }
    },
    created() {
        this.allTags = new Set()
        for (let i = 0; i < data.length; i++)
            for (let j = 0; j < data[i].Tags.length; j++)
                this.allTags.add(data[i].Tags[j])

        this.filter = new Set()

        this.getPageData()
    },
    methods: {
        getPageData() {
            this.filterData = []
            for (let i = 0; i < data.length; i++) {
                var rightCount = 0
                this.filter.forEach((value) => {
                    if (data[i].Tags.indexOf(value) >= 0)
                        rightCount += 1
                })
                if (rightCount === this.filter.size &&
                    (this.keyword === '' || data[i].Name.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0 || data[i].Artists.join(',').toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0)
                )
                    this.filterData.push(data[i])
            }

            this.pageList = this.filterData.slice(this.startIndex, this.startIndex + this.pageSize)
            this.totalCount = this.filterData.length
        },
        copySongName(row) {
            toClipboard('点歌 ' + row.Name)
            ElNotification({
                title: '复制成功',
                message: '点歌 ' + row.Name,
                type: 'success',
                duration: 2000,
                offset: 100
            })
        },
        pageChange(page) {
            this.startIndex = (page - 1) * this.pageSize
            this.getPageData()
        },
        checkAllChange(value) {
            if (value) {
                this.isCheckAll = true
                this.filter.clear()
            }
            else {
                this.isCheckAll = false
            }
            this.getPageData()
        },
        tagChange(tag, value) {
            if (value)
                this.filter.add(tag)
            else
                this.filter.delete(tag)
            this.getPageData()
        },
        roll() {
            var randomIndex = Math.floor(Math.random() * this.filterData.length + 0)
            this.copySongName(this.filterData[randomIndex])
        },
    }
}
</script>

<style>
.el-check-tag:hover {
    background-color: var(--el-color-primary-light-7);
    color: var(--el-color-primary);
}

.el-check-tag {
    font-size: 14px;
    padding: 12px 25px;
    margin: 10px 10px 0 0;
    background-color: var(--el-color-primary-light-7);
    color: #333333
}

.el-check-tag.is-checked:hover {
    background-color: var(--el-color-primary);
}

.el-check-tag.is-checked {
    background-color: var(--el-color-primary);
    color: var(--el-color-white);
}

.el-input {
    margin: 10px 0px 0 0;
    font-size: 14px;
    background-color: var(--el-color-primary-light-9);
    border-radius: var(--el-input-border-radius);
    --el-input-border-color: var(--el-color-primary-light-5);
    --el-input-hover-border-color: var(--el-color-primary-light-3);
    --el-input-icon-color: var(--el-color-primary-light-3);
    --el-input-clear-hover-color: var(--el-color-primary);
}

.button-roll {
    margin: 10px 0 0 10px;
    width: 100px;
    height: 40px;
    font-size: 14px;
    --el-button-border-color: var(--el-color-primary-light-5);
    --el-button-bg-color: var(--el-color-primary-light-7);
    --el-button-text-color: #333333;
    --el-button-hover-text-color: white;
    --el-button-hover-bg-color: var(--el-color-primary);
    --el-button-hover-border-color: var(--el-color-primary);
}

.el-table {
    --el-table-header-text-color: #666666;
    --el-table-text-color: #333333;
    --el-table-bg-color: rgba(255, 255, 255, 0.6);
    --el-table-header-bg-color: var(--el-color-primary-light-7);
    --el-table-row-hover-bg-color: var(--el-color-primary-light-7);
    --el-table-border-color: var(--el-color-primary-light-5);

    font-size: 18px;
    margin: 10px 0 20px 0;
}

.el-table__header {
    font-family: 'CangerYuyang';
}

.el-table .el-table__cell {
    padding: 12px 0;
    z-index: 0;
}

.el-pagination {
    justify-content: center;
    --el-pagination-button-bg-color: var(--el-color-primary-light-7);
}

.el-pagination.is-background .btn-prev:disabled {
    background-color: var(--el-color-primary-light-9);
}

.el-pagination.is-background .btn-next:disabled {
    background-color: var(--el-color-primary-light-9);
}

.el-notification .el-notification--success {
    color: var(--el-color-primary)
}
</style>
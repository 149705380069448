import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import App from './App.vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { createRouter, createWebHistory } from "vue-router";

import Index from './components/Index.vue'
import SongList from './components/SongList.vue'
import Fanart from './components/Fanart.vue'
import DDL from './components/DDL.vue'

const app = createApp(App)
app.use(ElementPlus)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

const routes = [
    { name: 'index', path: '/', component: Index },
    { name: 'songList', path: '/songList', component: SongList },
    { name: 'fanart', path: '/fanart', component: Fanart },
    { name: 'ddl', path: '/ddl', component: DDL }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        el: to.hash,
                        top: 120,
                        behavior: 'smooth'
                    })
                }, 0)
            })
        }
        else {
            return { top: 0, behavior: 'smooth' }
        }
    }
})
app.use(router)
app.config.globalProperties.console = console
app.mount('#app')